import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Меню | Меню спортивного бару Вибагливий Хлоп
			</title>
			<meta name={"description"} content={"У спортивному барі Вибагливий Хлоп наше меню - це окрема ліга, що пропонує різноманітні смаки, які задовольнять кожного вболівальника."} />
			<meta property={"og:title"} content={"Меню | Меню спортивного бару Вибагливий Хлоп"} />
			<meta property={"og:description"} content={"У спортивному барі Вибагливий Хлоп наше меню - це окрема ліга, що пропонує різноманітні смаки, які задовольнять кожного вболівальника."} />
			<meta property={"og:image"} content={"https://carnitahustin.com/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://carnitahustin.com/media/7128098.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://carnitahustin.com/media/7128098.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://carnitahustin.com/media/7128098.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://carnitahustin.com/media/7128098.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://carnitahustin.com/media/7128098.png"} />
			<meta name={"msapplication-TileImage"} content={"https://carnitahustin.com/media/7128098.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 20px 0" quarkly-title="Statistics-14">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 10px 0px" text-align="center" font="normal 500 56px/1.2 --fontFamily-serifTimes">
				Меню
			</Text>
			<Text
				margin="0px 250px 70px 250px"
				text-align="center"
				font="--base"
				color="#454c52"
				lg-margin="0px 100px 70px 100px"
				md-margin="0px 0 50px 0"
			>
				У спортивному барі Вибагливий Хлоп наше меню - це окрема ліга, що пропонує різноманітні смаки, які задовольнять кожного вболівальника.
			</Text>
		</Section>
		<Section padding="20px 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Начос на боковій лінії
					</Text>
					<Image src="https://carnitahustin.com/media/menu-1.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Величезна тарілка хрустких чіпсів з тортильї, вкрита плавленим сиром, халапеньйо та ситною порцією гуакамоле.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Крильця на старті
					</Text>
					<Image src="https://carnitahustin.com/media/menu-2.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Вибирайте на свій смак - гострі буйволячі, димчасті барбекю або пікантні медово-часникові, які подаються з прохолодним соусом ранчо або блакитним сиром.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Бургер "Лайнбекер"
					</Text>
					<Image src="https://carnitahustin.com/media/menu-3.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Величезна яловича котлета, покрита сиром, беконом і нашим фірмовим соусом Вибагливий Хлоп", загорнута в підсмажену булочку-бріош.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="50px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Розливне All-Stars
					</Text>
					<Image src="https://carnitahustin.com/media/menu-4.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Асортимент крафтового пива, що змінюється, ідеально підходить для тосту за успіх вашої команди.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="50px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Коктейль Huddle
					</Text>
					<Image src="https://carnitahustin.com/media/menu-5.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Наш фірмовий коктейль, що поєднує в собі освіжаючі смаки, щоб підтримати ваш настрій, незалежно від рахунку.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="50px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Брауні Triple Play
					</Text>
					<Image src="https://carnitahustin.com/media/menu-6.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Потрійний брауні з горіхами, карамеллю та кулькою ванільного морозива.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="80%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Лише найцікавіше
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Це меню - лише короткий огляд того, що ми пропонуємо в Вибагливий Хлоп. Щоб отримати повний асортимент або обговорити конкретні дієтичні потреби, не соромтеся звертатися до нас. Ми прагнемо забезпечити виграшне поєднання смаку та розваг, яке підійде кожному вболівальнику.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});